export function parseNumber<D extends any>(val: any, defaultVal: D): number | D;
export function parseNumber(val: any, defaultVal?: number): number;
/**
 * Parses a value to a number or the default value if the value is not parsable to a number.
 * Defaults to NaN if no default value is provided. We may want to change this default to
 * `null` or `undefined` in the future because Javascript is kinda funky with how it handles
 * NaN, but keeping it as is for now for backwards compatibility.
 */
export function parseNumber(...args: any[]) {
  if (args.length === 1) {
    return parseNumber(args[0], NaN);
  }

  const [val, defaultVal] = args;

  if (val === null) {
    // JS parses "null" to 0, which is probably not what we are expecting in the common case.
    return defaultVal;
  }
  const parsed = Number(val);
  return Number.isNaN(parsed) ? defaultVal : parsed;
}

/** Returns an array of integers from start (inclusive) up to end (exclusive). Range can be increasing or decreasing. */
export const rangeArray = (startParam: number, endParam: number) => {
  // Prevent NaN values or decimals from causing issues
  const start = Number.isNaN(startParam) ? 0 : Math.floor(startParam);
  const end = Number.isNaN(endParam) ? 0 : Math.floor(endParam);

  if (start > end) {
    // Add 1 here to keep end exclusive
    return [...Array(start - end).keys()].map((num) => num + end + 1).reverse();
  }

  return [...Array(end - start).keys()].map((num) => num + start);
};

export const padDecimal = (value: string, numDecimalPlaces: number = 4) => {
  const [whole, fractional] = value.split(".", 2);
  // remove extra zeros after fractional value
  const cleanFractional = fractional ? fractional.replace(/0+$/, "") : "";
  const decimal = cleanFractional
    ? cleanFractional.slice(0, Math.max(cleanFractional.length, numDecimalPlaces))
    : "";
  const numDecimalToAdd = Math.max(numDecimalPlaces - decimal.length, 0);
  return `${whole}.${decimal}${"0".repeat(numDecimalToAdd)}`;
};

export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

/** Returns a string representation of a number without trailing decimal 0's */
export const toNumberDisplay = (value: string | number) => {
  return `${parseFloat(`${value}`)}`;
};

/**
 * Takes a string input intended to represent a numerical value and
 * ensures that it only contains digits and at most one decimal point "."
 *
 * eg: Allows "1", "1.2", "1."" but not "1.2.3" or "1.."" etc
 * */
export const cleanNumberInput = (
  s: string,
  { isDecimal }: { isDecimal: boolean } = { isDecimal: false },
) => {
  const replaceRegex = isDecimal ? /[^0-9.]/g : /[^0-9]/g;

  const match = s.replace(replaceRegex, "").match(/\d*\.?\d*/);
  if (!match) {
    return "";
  }
  return match[0];
};

export const formatNumber = (value: number | string) => {
  if (typeof value !== "number") {
    // Guard against non-numeric values
    return value;
  }

  return value.toLocaleString();
};
